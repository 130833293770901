<template>
  <v-container>
    <v-row justify="center">
      <v-col
        sm="8"
        md="6"
        lg="4"
      >
        <validation-observer ref="observer">
          <v-form
            ref="form"
            lazy-validation
            :disabled="isSubmitting"
            @submit.prevent="reject"
          >
            <v-card>
              <v-card-title>
                <span class="text-h5">Reject PPR</span>
              </v-card-title>
              <v-card-text>
                <p>
                  Please fill in the reason for reject this PPR below, and click
                  Reject PPR to continue.
                </p>
                <validation-provider
                  v-slot="{ errors }"
                  name="reason"
                  rules="required"
                >
                  <v-textarea
                    label="Reason"
                    auto-grow
                    required
                    v-model="reason"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="primary"
                  rounded
                  type="submit"
                  :loading="isSubmitting"
                >
                  Reject PPR
                </v-btn>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-form>
        </validation-observer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {required} from "vee-validate/dist/rules";
import {
  extend,
  ValidationProvider,
  ValidationObserver,
  setInteractionMode
} from "vee-validate";
import {supabase} from "../../../supabase";

export default {
  name: "Reject",
  props: {
    id: {type: String, required: true},
    email: {type: String, required: true}
  },

  created() {
    setInteractionMode("eager");

    extend("required", {
      ...required,
      message: "Required"
    });
  },

  components: {
    ValidationProvider,
    ValidationObserver
  },

  data: () => ({
    isSubmitting: false,
    reason: ""
  }),

  methods: {
    reject: function () {
      this.isSubmitting = true;
      this.$refs.observer
        .validate()
        .then(async isValid => {
          if (isValid) {
            await supabase.functions.invoke("arrivals-linkReject", {
              body: {
                id: this.id,
                email: this.email,
                reason: this.reason
              }
            });

            this.$router.replace("/admin/dashboard");
          }
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    }
  }
};
</script>
