<template>
  <v-container>
    <v-row justify="center">
      <v-col
        sm="8"
        md="6"
        lg="4"
      >
        <validation-observer ref="observer">
          <v-form
            ref="form"
            lazy-validation
            :disabled="isSubmitting"
            @submit.prevent="approve"
          >
            <v-card>
              <v-card-title>
                <span class="text-h5">Approve PPR</span>
              </v-card-title>
              <v-card-text>
                <p>
                  Please click Approve PPR to continue.
                </p>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="primary"
                  rounded
                  type="submit"
                  :loading="isSubmitting"
                >
                  Approve PPR
                </v-btn>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-form>
        </validation-observer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {required} from "vee-validate/dist/rules";
import {extend, ValidationObserver, setInteractionMode} from "vee-validate";
import {supabase} from "../../../supabase";

export default {
  name: "Approve",
  props: {
    id: {type: String, required: true},
    email: {type: String, required: true}
  },

  created() {
    setInteractionMode("eager");

    extend("required", {
      ...required,
      message: "Required"
    });
  },

  components: {
    ValidationObserver
  },

  data: () => ({
    isSubmitting: false
  }),

  methods: {
    approve: function () {
      this.isSubmitting = true;
      this.$refs.observer
        .validate()
        .then(async isValid => {
          if (isValid) {
            await supabase.functions.invoke("arrivals-linkApprove", {
              body: {
                id: this.id,
                email: this.email
              }
            });

            this.$router.replace("/admin/dashboard");
          }
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    }
  }
};
</script>
